@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  height: 80vh;
  background: #E9ECEF;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-spinner {
  animation: rotate360 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid #DEE2E6;
  border-right: 2px solid #DEE2E6;
  border-bottom: 2px solid #DEE2E6;
  border-left: 4px solid #212529;
  background: #E9ECEF;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 20px;
  right: 30px;
  background-color: #02ca69;
  color: #DEE2E6;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #999;
  z-index: 100;
  text-decoration: none;
}

.my-float {
  margin-top: 10px;
}

.text {
  font-size: 12px;
  font-weight: bold;
  color: #DEE2E6;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

/* inter-300 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-Thin.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-Thin.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-ExtraLight.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-ExtraLight.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-Light.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-Light.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-Regular.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-Regular.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-Medium.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-Medium.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-SemiBold.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-SemiBold.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-Bold.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-Bold.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-ExtraBold.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-ExtraBold.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(""), url("assets/fonts/Inter-Black.ttf") format("ttf"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("assets/fonts/Inter-Black.ttf")
      format("ttf"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
